nav {
  align-items: center;
  background-color: $black;
  box-sizing: border-box;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  @include breakpoint-for(lg) {
    height: 77px;
    padding: 0 100px 0 60px;
  }

  @include breakpoint-for(sm) {
    padding: 10px;
  }

  & > a {
    color: white;
    text-decoration: none;

    & > h1 {
      font-family: SourceSansPro-Bold, sans-serif;
      font-size: 1.15rem;
      font-weight: 800;
      letter-spacing: 0.3rem;
      margin: 0 0 3px 0;

      @include breakpoint-for(lg) {
        margin-left: 50px;
      }
    }

    & > p {
      font-family: SourceSansPro-Regular, sans-serif;
      font-size: 0.7rem;
      font-weight: 600;
      letter-spacing: 0.215rem;
      margin: 0;
      text-align: center;

      @include breakpoint-for(lg) {
        margin-left: 50px;
      }
    }
  }

  & > div {
    display: flex;

    &:nth-child(2) {
      align-items: flex-end;
      flex-direction: column;
      height: 21px;
      justify-content: space-between;
      width: 33px;

      @include breakpoint-for(lg) {
        display: none;
      }

      & > span {
        background-color: white;
        height: 3px;
        transform: rotate(0);
        transform-origin: left center;
        transition: 0.6s;
        width: 25px;

        &:nth-child(2) {
          align-self: flex-start;
        }
      }

      &.active {
        & > span {
          background-color: white;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            margin-bottom: 1px;
            transform: rotate(-45deg);
          }
        }
      }
    }

    &:nth-child(3) {
      background-color: $black;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: space-evenly;
      left: 0;
      padding: 70px 20px 0;
      position: absolute;
      top: 0;
      transform: translateX(-130%);
      transition: 0.6s;
      width: 100%;
      z-index: -1;

      & > a {
        color: white;
        font-size: 1.1rem;
        font-weight: 700;
        margin: 20px 10px;
        text-decoration: none;

        &:last-child{
          color:$red;
          padding-bottom: 30px;
        }

        @include breakpoint-for(lg) {
          &:last-child {
            background-color: $red;
            border-radius: 4px;
            color: white;
            padding: 2px 20px;
          }
        }
      }

      @include breakpoint-for(lg) {
        background-color: $black;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 60px;
        padding: 0;
        position: static;
        transform: translateX(0);
      }
     
      &.active {
        transform: translateX(0);
      }

      & > div {
        border-top: 1px solid blue;

        @include breakpoint-for(lg) {
          display: none;
        }
      }
    }
  }
}
