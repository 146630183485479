@charset "UTF-8";
@font-face {
  font-family: Barlow-Regular;
  src: url("../assets/fonts/Barlow-Regular.ttf") format("truetype"), url("../assets/fonts/Barlow-Regular.ttf") format("opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: Barlow-Bold;
  src: url("../assets/fonts/Barlow-Bold.ttf") format("truetype"), url("../assets/fonts/Barlow-Bold.ttf") format("opentype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: Morganite-Bold;
  src: url("../assets/fonts/Morganite-Bold.ttf") format("truetype"), url("../assets/fonts/Morganite-Bold.ttf") format("opentype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: Morganite-ExtraBold;
  src: url("../assets/fonts/Morganite-ExtraBold.ttf") format("truetype"), url("../assets/fonts/Morganite-ExtraBold.ttf") format("opentype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: Oswald-SemiBold;
  src: url("../assets/fonts/Oswald-SemiBold.ttf") format("truetype"), url("../assets/fonts/Oswald-SemiBold.ttf") format("opentype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: Oswald-Bold;
  src: url("../assets/fonts/Oswald-Bold.ttf") format("truetype"), url("../assets/fonts/Oswald-Bold.ttf") format("opentype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: SourceSansPro-Regular;
  src: url("../assets/fonts/SourceSansPro-Regular.ttf") format("truetype"), url("../assets/fonts/SourceSansPro-Regular.ttf") format("opentype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: SourceSansPro-Bold;
  src: url("../assets/fonts/SourceSansPro-Bold.ttf") format("truetype"), url("../assets/fonts/SourceSansPro-Bold.ttf") format("opentype");
  font-weight: 800;
  font-style: bold; }

* {
  font-family: Barlow-Regular, sans-serif; }

body {
  background-color: #000000;
  margin: 0; }

nav {
  align-items: center;
  background-color: black;
  box-sizing: border-box;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; }
  @media (min-width: 992px) {
    nav {
      height: 77px;
      padding: 0 100px 0 60px; } }
  @media (min-width: 576px) {
    nav {
      padding: 10px; } }
  nav > a {
    color: white;
    text-decoration: none; }
    nav > a > h1 {
      font-family: SourceSansPro-Bold, sans-serif;
      font-size: 1.15rem;
      font-weight: 800;
      letter-spacing: 0.3rem;
      margin: 0 0 3px 0; }
      @media (min-width: 992px) {
        nav > a > h1 {
          margin-left: 50px; } }
    nav > a > p {
      font-family: SourceSansPro-Regular, sans-serif;
      font-size: 0.7rem;
      font-weight: 600;
      letter-spacing: 0.215rem;
      margin: 0;
      text-align: center; }
      @media (min-width: 992px) {
        nav > a > p {
          margin-left: 50px; } }
  nav > div {
    display: flex; }
    nav > div:nth-child(2) {
      align-items: flex-end;
      flex-direction: column;
      height: 21px;
      justify-content: space-between;
      width: 33px; }
      @media (min-width: 992px) {
        nav > div:nth-child(2) {
          display: none; } }
      nav > div:nth-child(2) > span {
        background-color: white;
        height: 3px;
        transform: rotate(0);
        transform-origin: left center;
        transition: 0.6s;
        width: 25px; }
        nav > div:nth-child(2) > span:nth-child(2) {
          align-self: flex-start; }
      nav > div:nth-child(2).active > span {
        background-color: white; }
        nav > div:nth-child(2).active > span:nth-child(1) {
          transform: rotate(45deg); }
        nav > div:nth-child(2).active > span:nth-child(2) {
          opacity: 0; }
        nav > div:nth-child(2).active > span:nth-child(3) {
          margin-bottom: 1px;
          transform: rotate(-45deg); }
    nav > div:nth-child(3) {
      background-color: black;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: space-evenly;
      left: 0;
      padding: 70px 20px 0;
      position: absolute;
      top: 0;
      transform: translateX(-130%);
      transition: 0.6s;
      width: 100%;
      z-index: -1; }
      nav > div:nth-child(3) > a {
        color: white;
        font-size: 1.1rem;
        font-weight: 700;
        margin: 20px 10px;
        text-decoration: none; }
        nav > div:nth-child(3) > a:last-child {
          color: #FF0000;
          padding-bottom: 30px; }
        @media (min-width: 992px) {
          nav > div:nth-child(3) > a:last-child {
            background-color: #FF0000;
            border-radius: 4px;
            color: white;
            padding: 2px 20px; } }
      @media (min-width: 992px) {
        nav > div:nth-child(3) {
          background-color: black;
          flex-direction: row;
          justify-content: flex-end;
          margin-right: 60px;
          padding: 0;
          position: static;
          transform: translateX(0); } }
      nav > div:nth-child(3).active {
        transform: translateX(0); }
      nav > div:nth-child(3) > div {
        border-top: 1px solid blue; }
        @media (min-width: 992px) {
          nav > div:nth-child(3) > div {
            display: none; } }

header {
  background-image: url(" https://transformacao-digital-files.s3-sa-east-1.amazonaws.com/mobile/m-banner.png");
  background-position: center;
  background-size: cover;
  width: 100%; }
  @media (min-width: 992px) {
    header {
      background-image: url(" https://transformacao-digital-files.s3-sa-east-1.amazonaws.com/desktop/d-banner.png"); } }
  header > p {
    color: white;
    font-family: Barlow-Medium, sans-serif;
    font-size: 1rem;
    padding: 2px 3px 5px 3px;
    position: absolute;
    text-align: center;
    text-decoration: line-through;
    z-index: 999; }
    @media (min-width: 992px) {
      header > p {
        font-size: 1.6rem; } }
    header > p:nth-child(1) {
      background-color: black;
      left: 37px;
      top: 80px;
      width: 80px; }
      @media (min-width: 992px) {
        header > p:nth-child(1) {
          left: 9%;
          top: 17%;
          width: 90px; } }
    header > p:nth-child(2) {
      background-color: #FF0000;
      left: 95px;
      text-decoration: none;
      top: 120px;
      width: 70px; }
      @media (min-width: 992px) {
        header > p:nth-child(2) {
          left: 12%;
          top: 25%;
          width: 80px; } }
    header > p:nth-child(3) {
      background-color: black;
      left: 42px;
      top: 160px;
      width: 20px; }
      @media (min-width: 992px) {
        header > p:nth-child(3) {
          left: 10%;
          top: 35%;
          width: 50px; } }
    header > p:nth-child(4) {
      background-color: black;
      left: 80px;
      top: 190px;
      width: 50px; }
      @media (min-width: 992px) {
        header > p:nth-child(4) {
          left: 10%;
          top: 45%;
          width: 80px; } }
    header > p:nth-child(5) {
      background-color: black;
      left: 200px;
      top: 240px;
      width: 80px; }
      @media (min-width: 992px) {
        header > p:nth-child(5) {
          left: 50%;
          top: 52%;
          width: 130px; } }
    header > p:nth-child(6) {
      background-color: black;
      left: 70px;
      text-decoration: none;
      top: 420px;
      width: 100px; }
      @media (min-width: 992px) {
        header > p:nth-child(6) {
          left: 51%;
          top: 60%;
          width: 170px; } }
    header > p:nth-child(7) {
      background-color: black;
      left: 200px;
      top: 500px;
      width: 120px; }
      @media (min-width: 992px) {
        header > p:nth-child(7) {
          left: 70%;
          top: 68%;
          width: 260px; } }
    header > p span {
      color: #FF0000;
      font-family: Barlow-Medium, sans-serif;
      font-size: 1rem; }
      @media (min-width: 992px) {
        header > p span {
          font-size: 1.6rem; } }
    header > p:nth-child(8) {
      background-color: black;
      left: 230px;
      top: 540px;
      width: 80px; }
      @media (min-width: 992px) {
        header > p:nth-child(8) {
          left: 76%;
          top: 75%;
          width: 130px; } }
    header > p:nth-child(9) {
      bottom: 80px;
      color: white;
      font-size: 0.8rem;
      left: 16px;
      text-decoration: none; }
      @media (min-width: 992px) {
        header > p:nth-child(9) {
          bottom: 8%;
          font-size: 1rem;
          left: 60px;
          letter-spacing: 5px; } }
    header > p:nth-child(10) {
      bottom: 30px;
      color: white;
      font-size: 0.8rem;
      left: 16px;
      text-align: left;
      text-decoration: none; }
      @media (min-width: 992px) {
        header > p:nth-child(10) {
          bottom: 5%;
          font-size: 1rem;
          left: 60px;
          letter-spacing: 5px; } }

#about {
  color: white;
  font-size: 1rem;
  letter-spacing: 0.29px;
  line-height: 25px;
  padding-left: 20px;
  width: 88%; }
  @media (min-width: 992px) {
    #about {
      display: flex;
      font-size: 1.3rem; } }
  #about > div:nth-child(1) h2 {
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 5.4px;
    padding: 30px 0;
    text-decoration: line-through; }
  @media (min-width: 992px) {
    #about > div:nth-child(1) {
      margin-left: 60px; }
      #about > div:nth-child(1) h2 {
        font-size: 1.8rem;
        letter-spacing: 8.32px;
        margin-bottom: 20px;
        margin-left: 12px; }
      #about > div:nth-child(1) p {
        letter-spacing: 0.17px;
        transform: skewY(-2deg);
        width: 90%; } }
  #about span {
    color: #FF0000;
    text-decoration: line-through; }
  #about > div:nth-child(2) div {
    height: 0;
    overflow: hidden;
    position: static;
    width: 100%; }
    #about > div:nth-child(2) div.active {
      height: 100%; }
    #about > div:nth-child(2) div h2 {
      text-align: right; }
    @media (min-width: 992px) {
      #about > div:nth-child(2) div {
        height: 100%;
        padding-top: 100px; }
        #about > div:nth-child(2) div p {
          letter-spacing: 0.17px;
          opacity: 1;
          transform: skewY(-1.5deg); }
        #about > div:nth-child(2) div h2 {
          font-size: 1.8rem;
          letter-spacing: 8.32px;
          margin-left: 12px;
          margin-top: 50px; } }
  #about > div:nth-child(2) button {
    background-color: transparent;
    border: none;
    color: #FF0000;
    cursor: pointer;
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
    height: 60px;
    margin-top: 30px;
    outline: none;
    width: 100%; }
    @media (min-width: 992px) {
      #about > div:nth-child(2) button {
        display: none; } }

.video {
  display: none; }
  @media (min-width: 992px) {
    .video {
      display: block;
      margin: 150px 0;
      text-align: center; } }

.title-course > h2 {
  color: #ffffff;
  font-family: Morganite-Bold, sans-serif;
  font-size: 3.5rem;
  letter-spacing: 5.57px;
  margin: 100px 16px 30px; }
  @media (min-width: 992px) {
    .title-course > h2 {
      justify-content: center;
      font-size: 7.5rem;
      margin: 30px 60px; } }

.overlay {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.429);
  left: 0;
  height: 100%;
  position: fixed;
  overflow: auto;
  top: 0;
  width: 100%;
  z-index: -2; }

.course {
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  margin: 0 16px; }
  @media (min-width: 992px) {
    .course {
      margin: 30px 45px; } }
  .course div {
    box-sizing: border-box;
    cursor: pointer;
    padding: 20px 0 30px;
    text-align: center;
    width: 100%; }
    @media (min-width: 992px) {
      .course div {
        padding: 30px 20px;
        width: 48%; } }
  .course .image-frame {
    align-items: flex-end;
    background-position: center;
    background-size: cover;
    display: flex;
    height: 200px;
    margin: 0;
    width: 100%; }
    @media (min-width: 992px) {
      .course .image-frame {
        align-items: center;
        justify-content: center;
        height: 400px; } }
  .course label {
    color: #FF0000;
    border: 1px solid #FF0000;
    bottom: 32px;
    border-radius: 10px;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 4px 17px;
    position: relative; }
    @media (min-width: 992px) {
      .course label {
        bottom: 40px;
        font-size: 1.1rem; } }
  .course h3 {
    font-family: Morganite-Bold, sans-serif;
    font-size: 2rem;
    letter-spacing: 0.9px;
    margin: 3px;
    text-align: center;
    text-transform: uppercase;
    opacity: 1; }
    @media (min-width: 992px) {
      .course h3 {
        margin-top: 15px;
        font-size: 3.2rem; } }
  .course span {
    font-size: 0.8rem;
    letter-spacing: 0.11px;
    margin-bottom: 20px;
    opacity: 1;
    text-align: center; }
    @media (min-width: 992px) {
      .course span {
        font-size: 1.2rem;
        padding: 20px; } }

.teacher {
  color: #ffffff; }
  @media (min-width: 992px) {
    .teacher {
      margin-top: 100px; } }
  .teacher h2 {
    font-family: Morganite-Bold, sans-serif;
    font-size: 4rem;
    letter-spacing: 5.57px;
    margin: 50px 16px; }
    @media (min-width: 992px) {
      .teacher h2 {
        font-size: 7.5rem;
        margin: 50px;
        text-align: right;
        width: 85%; } }
  .teacher button {
    background-color: transparent;
    border: 1px solid #FF0000;
    border-radius: 100px;
    color: #FF0000;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    height: 30px;
    margin-top: 30px;
    margin-left: 30%;
    text-transform: uppercase;
    width: 140px; }
    @media (min-width: 992px) {
      .teacher button {
        margin-left: 40%; } }
  .teacher .bio {
    display: flex;
    height: 0;
    overflow: hidden;
    position: static;
    flex-direction: column;
    font-size: 1rem;
    justify-content: center;
    letter-spacing: 0.19px;
    margin: 30px 16px; }
    .teacher .bio.active {
      height: 100%; }
    .teacher .bio p {
      line-height: 30px;
      margin: 5px 0; }
      @media (min-width: 992px) {
        .teacher .bio p {
          font-size: 1.2rem;
          margin-left: 40px;
          padding-bottom: 10px;
          text-align: justify;
          width: 70%; } }
  .teacher .photo-teacher {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 992px) {
      .teacher .photo-teacher {
        display: flex;
        flex-direction: row;
        justify-content: left;
        margin: 0 60px;
        min-height: 500px;
        width: 80%; } }
    .teacher .photo-teacher > div {
      margin: 0 16px 40px; }
      @media (min-width: 992px) {
        .teacher .photo-teacher > div {
          margin: 0; } }
      .teacher .photo-teacher > div img {
        width: 95%; }
        @media (min-width: 992px) {
          .teacher .photo-teacher > div img {
            width: 370px;
            margin: 0; } }
      .teacher .photo-teacher > div p {
        font-size: 1.2rem;
        letter-spacing: 0.19px;
        opacity: 1;
        text-align: left; }

.title-mastertech h2 {
  color: #FF0000;
  font-family: Morganite-Bold, sans-serif;
  font-size: 5.5rem;
  letter-spacing: 2.3px;
  margin: 60px 40px; }
  @media (min-width: 992px) {
    .title-mastertech h2 {
      font-size: 7.5rem;
      left: 45px;
      margin-left: 140px;
      margin-top: 150px;
      position: relative;
      top: 110px;
      width: 80%; } }

.title-mastertech img {
  display: none; }
  @media (min-width: 992px) {
    .title-mastertech img {
      display: block;
      margin-left: 120px; } }

.mastertech {
  color: #ffffff;
  min-height: 400px; }
  @media (min-width: 992px) {
    .mastertech {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      margin: 30px 150px;
      min-height: 600px; } }
  @media (min-width: 400px) {
    .mastertech {
      margin: 60px; } }
  .mastertech > div {
    margin: 0 30px; }
    .mastertech > div > div:nth-child(1) {
      align-items: center;
      border-bottom: 1px solid #FF0000;
      display: flex;
      justify-content: space-between; }
      @media (min-width: 992px) {
        .mastertech > div > div:nth-child(1) {
          border: none; } }
      .mastertech > div > div:nth-child(1) h3 {
        font-size: 1.1rem; }
        @media (min-width: 992px) {
          .mastertech > div > div:nth-child(1) h3 {
            color: #FF0000;
            font-family: Morganite-Bold, sans-serif;
            font-size: 7.5rem;
            letter-spacing: 0.97px;
            margin: 0; } }
      .mastertech > div > div:nth-child(1) > img {
        width: 12px; }
        @media (min-width: 992px) {
          .mastertech > div > div:nth-child(1) > img {
            display: none; } }
    .mastertech > div .business {
      height: 0;
      overflow: hidden;
      position: static;
      width: 100%; }
      .mastertech > div .business span {
        text-decoration: line-through; }
      @media (min-width: 992px) {
        .mastertech > div .business {
          font-size: 1.1rem;
          font-weight: 100;
          height: 100%; } }
      .mastertech > div .business.active {
        height: 100%; }
      .mastertech > div .business > ul {
        list-style-type: none;
        padding: 0 20px; }
        @media (min-width: 992px) {
          .mastertech > div .business > ul {
            padding: 0; }
            .mastertech > div .business > ul > li {
              padding-bottom: 10px; } }

footer {
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.534);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0px 20px;
  width: 100%; }
  @media (min-width: 992px) {
    footer {
      align-items: flex-start;
      padding-bottom: 40px;
      min-height: 400px; } }
  footer > div {
    width: 90%; }
    footer > div .video-mpills {
      display: none; }
      @media (min-width: 992px) {
        footer > div .video-mpills {
          display: flex;
          flex-direction: column;
          margin-left: 60px;
          justify-content: space-around; } }
      footer > div .video-mpills h3 {
        font-size: 1.31rem; }
        @media (min-width: 992px) {
          footer > div .video-mpills h3 {
            font-size: 1.55rem; } }
    @media (min-width: 992px) {
      footer > div {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 60px;
        width: 80%; } }
    footer > div .about-mt,
    footer > div .product-mt,
    footer > div .contact {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0; }
      @media (min-width: 992px) {
        footer > div .about-mt,
        footer > div .product-mt,
        footer > div .contact {
          align-items: flex-start; } }
      footer > div .about-mt h3,
      footer > div .product-mt h3,
      footer > div .contact h3 {
        font-size: 1.3rem; }
        @media (min-width: 992px) {
          footer > div .about-mt h3,
          footer > div .product-mt h3,
          footer > div .contact h3 {
            font-size: 1.55rem; } }
      footer > div .about-mt > a,
      footer > div .product-mt > a,
      footer > div .contact > a {
        color: white;
        padding-top: 10px;
        text-decoration: none; }
        @media (min-width: 992px) {
          footer > div .about-mt > a,
          footer > div .product-mt > a,
          footer > div .contact > a {
            font-size: 1.1rem; } }
    @media (min-width: 992px) {
      footer > div .product-mt .product-detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between; } }
    footer > div .product-mt .product-detail div {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around; }
      @media (min-width: 992px) {
        footer > div .product-mt .product-detail div {
          align-items: flex-start;
          padding-right: 40px; } }
      footer > div .product-mt .product-detail div h3 {
        font-size: 1.3rem; }
        @media (min-width: 992px) {
          footer > div .product-mt .product-detail div h3 {
            font-size: 1.5rem; } }
      footer > div .product-mt .product-detail div a {
        color: white;
        padding-top: 10px;
        text-decoration: none; }
        @media (min-width: 992px) {
          footer > div .product-mt .product-detail div a {
            font-size: 1.1rem; } }
  footer > .social {
    align-items: center;
    border-top: 0.8px solid rgba(255, 255, 255, 0.534);
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    @media (min-width: 992px) {
      footer > .social {
        align-items: flex-end;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 60px;
        width: 92%; } }
    footer > .social .logo-footer {
      margin-top: 30px;
      width: 40px; }
    footer > .social:last-child a {
      padding-top: 10px;
      text-decoration: none; }
      footer > .social:last-child a img {
        padding: 3px;
        width: 30px; }
        @media (min-width: 992px) {
          footer > .social:last-child a img {
            width: 40px; } }

.carousel {
  background-color: white;
  box-sizing: border-box;
  padding: 60px 0;
  width: 100%; }
  @media (min-width: 992px) {
    .carousel {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 800px; } }
  .carousel .text {
    margin: 20px 45px; }
    @media (min-width: 992px) {
      .carousel .text {
        margin: 0px 60px;
        width: 50%; } }
    .carousel .text h2 {
      color: black;
      font-family: Morganite-ExtraBold, sans-serif;
      font-size: 5rem;
      letter-spacing: 2.3px;
      padding-top: 40px;
      margin: 0;
      text-align: center; }
      @media (min-width: 992px) {
        .carousel .text h2 {
          font-size: 7rem;
          letter-spacing: 6.05px;
          margin: 0;
          text-align: left; } }
    .carousel .text p {
      display: none; }
      @media (min-width: 992px) {
        .carousel .text p {
          display: block;
          font-size: 1.1rem;
          letter-spacing: 0.14px;
          margin: 0;
          text-align: left;
          width: 70%; } }
      .carousel .text p:nth-child(3) {
        padding-bottom: 20px; }
    .carousel .text a {
      text-decoration: none; }
    .carousel .text button {
      display: none; }
      @media (min-width: 992px) {
        .carousel .text button {
          background-color: transparent;
          border: none;
          color: #FF0000;
          cursor: pointer;
          display: block;
          font-size: 1rem;
          font-weight: bold;
          height: 60px;
          margin-top: 30px;
          outline: none;
          text-align: center; } }
  .carousel .slider-container {
    overflow: hidden; }
    @media (min-width: 992px) {
      .carousel .slider-container {
        padding: 10px;
        width: 50%; } }
    .carousel .slider-container .slides {
      display: flex;
      padding: 20px 0;
      position: relative;
      transition: transform 250ms ease-in;
      width: 100%; }
      .carousel .slider-container .slides a {
        box-sizing: border-box;
        flex-shrink: 0;
        padding: 0 16px;
        width: 100%; }
        .carousel .slider-container .slides a img {
          width: 100%; }
        @media (min-width: 992px) {
          .carousel .slider-container .slides a {
            width: 328px; } }
  .carousel .dots-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 50px; }
    .carousel .dots-container span {
      background-color: #cdd1ce;
      border: 1px solid #c8ccc9;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 12px;
      margin: 0 5px;
      width: 12px; }
      .carousel .dots-container span.active {
        background-color: #FF0000; }

.contact {
  color: white;
  box-sizing: border-box;
  margin: 0 40px; }
  @media (min-width: 992px) {
    .contact {
      margin: 20px 60px; } }
  .contact h2 {
    font-family: Morganite-Bold, sans-serif;
    font-size: 4rem;
    letter-spacing: 5.5px;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .contact h2 {
        color: #FF0000;
        font-size: 7rem; } }
  .contact p {
    font-size: 1.2rem;
    padding-bottom: 25px; }
  .contact .price > :first-child {
    text-decoration: line-through; }
  .contact .price p {
    font-size: 1rem;
    letter-spacing: 0.49px;
    margin: 0;
    padding-bottom: 5px; }
    @media (min-width: 992px) {
      .contact .price p {
        font-size: 1.16rem; } }
  .contact .price > :nth-child(3) {
    padding-bottom: 20px; }
  .contact .price > :last-child {
    padding-bottom: 60px; }
    @media (min-width: 992px) {
      .contact .price > :last-child {
        margin-bottom: 40px; } }
  .contact .form-lead {
    min-height: 550px; }
    @media (min-width: 992px) {
      .contact .form-lead {
        flex-direction: row;
        justify-content: left;
        min-height: 180px; } }
    .contact .form-lead .labels {
      display: none; }
      @media (min-width: 992px) {
        .contact .form-lead .labels {
          display: block; } }
      .contact .form-lead .labels label {
        cursor: pointer;
        margin-right: 50px;
        opacity: 0.47; }
        .contact .form-lead .labels label.active {
          opacity: 1; }
        .contact .form-lead .labels label.error {
          opacity: 1;
          color: #FF0000; }
    @media (min-width: 992px) {
      .contact .form-lead .fields {
        display: flex;
        overflow: hidden;
        width: 700px; } }
    .contact .form-lead .fields button {
      background-color: transparent;
      border: none;
      color: white;
      cursor: pointer;
      display: none;
      font-size: 1.5rem;
      height: 100%;
      margin: 0;
      outline: none;
      position: absolute;
      right: 40px; }
      .contact .form-lead .fields button:hover {
        border: none; }
      @media (min-width: 992px) {
        .contact .form-lead .fields button {
          display: inline; } }
    .contact .form-lead .fields div {
      position: relative;
      padding-bottom: 12px; }
      .contact .form-lead .fields div.error input {
        border-bottom: 1px solid #FF0000; }
        @media (min-width: 992px) {
          .contact .form-lead .fields div.error input {
            border: 1px solid #FF0000; } }
    .contact .form-lead .fields input {
      background-color: transparent;
      border-radius: 5px;
      border: none;
      border-bottom: 1px solid #555;
      box-sizing: border-box;
      color: white;
      font-size: 1.1rem;
      height: 40px;
      margin-bottom: 16px;
      margin-right: 30px;
      outline: none;
      padding-left: 10px;
      width: 100%; }
      .contact .form-lead .fields input:focus {
        border-bottom: 1px solid white; }
      @media (min-width: 992px) {
        .contact .form-lead .fields input {
          background-color: #191919;
          border-bottom: none;
          height: 70px;
          margin-top: 23px;
          width: 700px; }
          .contact .form-lead .fields input:focus {
            border-bottom: none; } }
  .contact button.mobile-submit-button {
    background-color: transparent;
    border: none;
    color: #FF0000;
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    height: 60px;
    margin: 30px auto 0;
    outline: none;
    width: 220px; }
    .contact button.mobile-submit-button:after {
      border-bottom: 1px solid #FF0000;
      content: "";
      display: block;
      margin: auto;
      width: 120px; }
    @media (min-width: 992px) {
      .contact button.mobile-submit-button {
        margin: 20px;
        width: 180px; }
        .contact button.mobile-submit-button[type=submit] {
          display: none; } }
  .contact .feedback {
    background-color: red;
    font-weight: bold;
    margin: 30px;
    padding: 3px; }
    @media (min-width: 992px) {
      .contact .feedback {
        margin: 0; } }
  .contact .text-checkbox {
    display: none; }
    @media (min-width: 992px) {
      .contact .text-checkbox {
        display: flex;
        font-size: 1.2rem;
        padding: 18px 0; } }
  .contact .checkbox-desktop {
    display: none;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 70px; }
    @media (min-width: 992px) {
      .contact .checkbox-desktop {
        display: flex; } }
    .contact .checkbox-desktop label {
      padding-left: 10px;
      box-sizing: border-box; }
    .contact .checkbox-desktop > div {
      width: 16.6%;
      display: flex;
      margin-bottom: 10px; }

.styled-checkbox {
  position: relative; }
  .styled-checkbox input[type=checkbox] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: -1; }
  .styled-checkbox span {
    border: 1px solid #FF0000;
    display: block;
    height: 15px;
    width: 15px;
    cursor: pointer;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .styled-checkbox input:checked + span {
    background: #FF0000; }
    .styled-checkbox input:checked + span:before {
      content: "✓"; }

.modal {
  background-color: #fff;
  display: none;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  transition: 0.5s;
  top: 0;
  z-index: 3; }
  .modal.right {
    right: 0;
    transform: translateX(100%); }
  .modal.left {
    left: 0;
    transform: translateX(-100%); }
  .modal.active {
    display: block; }
  .modal.visible {
    transform: translateX(0); }
  .modal .container {
    flex-direction: row;
    margin-top: 70px;
    padding: 20px; }
    @media (min-width: 992px) {
      .modal .container {
        padding: 20px 50px;
        overflow-y: auto;
        right: -50px; } }
    .modal .container button:first-child {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 1.3rem;
      font-weight: 700;
      margin: 20px 0;
      text-align: left;
      width: 100%; }
    .modal .container h2 {
      font-size: 3rem;
      font-family: Morganite-Bold, sans-serif;
      letter-spacing: 5.57px;
      margin: 40px 0;
      text-transform: uppercase; }
      @media (min-width: 992px) {
        .modal .container h2 {
          font-size: 7.5rem; } }
    .modal .container img {
      width: 95%; }
      @media (min-width: 992px) {
        .modal .container img {
          height: 315px;
          width: 560px; } }
    .modal .container h3 {
      font-family: Barlow-Bold, sans-serif;
      font-size: 1.2rem;
      font-style: italic; }
      @media (min-width: 992px) {
        .modal .container h3 {
          width: 700px; } }
    .modal .container p {
      font-size: 1rem;
      letter-spacing: 1.2px;
      padding-bottom: 5px; }
      @media (min-width: 992px) {
        .modal .container p {
          font-size: 1.1rem;
          letter-spacing: 1.3px;
          width: 723px; } }
  .modal .button-form {
    background-color: #FF0000;
    border: none;
    border-radius: 4px;
    color: black;
    font-weight: 600;
    font-size: 1rem;
    height: 32px;
    margin: 10px;
    padding: 2px 5px;
    text-align: center;
    text-transform: uppercase;
    width: 90%; }
    @media (min-width: 992px) {
      .modal .button-form {
        margin: 20px 50px;
        width: 300px; } }
  .modal .notice {
    font-style: italic;
    margin: 10px;
    padding: 2px 5px; }
    @media (min-width: 992px) {
      .modal .notice {
        margin: 5px 50px 20px; } }

@-webkit-keyframes bounceInUp {
  60%, 75%, 90%, from, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
    -moz-transform: translate3d(0, 3000px, 0);
    -ms-transform: translate3d(0, 3000px, 0);
    -o-transform: translate3d(0, 3000px, 0);
    -webkit-transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-moz-keyframes bounceInUp {
  60%, 75%, 90%, from, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -moz-transform: translate3d(0, 3000px, 0);
    -ms-transform: translate3d(0, 3000px, 0);
    -o-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-o-keyframes bounceInUp {
  60%, 75%, 90%, from, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -moz-transform: translate3d(0, 3000px, 0);
    -ms-transform: translate3d(0, 3000px, 0);
    -o-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  60%, 75%, 90%, from, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -moz-transform: translate3d(0, 3000px, 0);
    -ms-transform: translate3d(0, 3000px, 0);
    -o-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -moz-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    -o-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@-moz-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -moz-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    -o-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@-o-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -moz-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    -o-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -moz-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    -o-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

#cookie-banner-lgpd {
  background-color: #fff;
  border: solid 1px #eee;
  bottom: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  left: 16px;
  margin: auto;
  max-width: 1334px;
  min-height: 70px;
  position: fixed;
  right: 16px;
  z-index: 10000; }

#cookie-banner-lgpd.cookie-banner-lgpd-animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1; }

#cookie-banner-lgpd.cookie-banner-lgpd-animated.cookie-banner-lgpd-visible {
  animation-name: bounceInUp; }

#cookie-banner-lgpd.cookie-banner-lgpd-animated.cookie-banner-lgpd-hidden {
  animation-name: bounceOutDown; }

#cookie-banner-lgpd .cookie-banner-lgpd-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: inherit;
  width: 100%; }

#cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_text-box {
  padding: 16px;
  vertical-align: middle; }

#cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_text-box .cookie-banner-lgpd_text {
  color: #333;
  font-family: opensans, helvetica, arial, sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -.45px;
  line-height: normal; }

#cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_text-box .cookie-banner-lgpd-link {
  color: #0069de;
  cursor: pointer;
  text-decoration: underline; }

#cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_button-box {
  padding: 16px 16px 16px 0; }

#cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_button-box .cookie-banner-lgpd_accept-button {
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-family: opensans, helvetica, arial, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1;
  padding: 8px 16px;
  text-align: center; }

#cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_button-box .cookie-banner-lgpd_accept-button:focus {
  outline: 0; }

@media only screen and (max-width: 800px) {
  #cookie-banner-lgpd .cookie-banner-lgpd-container {
    flex-flow: column; }
  #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_text-box {
    padding: 16px 16px 0 16px; }
  #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_button-box {
    box-sizing: border-box;
    padding: 16px;
    width: 100%; }
  #cookie-banner-lgpd .cookie-banner-lgpd-container .cookie-banner-lgpd_button-box .cookie-banner-lgpd_accept-button {
    width: 100%; } }
