.title-mastertech {
  h2 {
    color: $red;
    font-family: Morganite-Bold, sans-serif;
    font-size: 5.5rem;
    letter-spacing: 2.3px;
    margin: 60px 40px;

    @include breakpoint-for(lg) {
      font-size: 7.5rem;
      left: 45px;
      margin-left: 140px;
      margin-top: 150px;
      position: relative;
      top: 110px;
      width: 80%;
    }
  }
  img {
    display: none;
    @include breakpoint-for(lg) {
      display: block;
      margin-left: 120px;
    }
  }
}

.mastertech {
  color: #ffffff;
  min-height: 400px;

  @include breakpoint-for(lg) {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 30px 150px;
    min-height: 600px;
  }

  @include breakpoint-for(xs) {
    margin: 60px;
  }

  & > div {
    margin: 0 30px;

    & > div:nth-child(1) {
      align-items: center;
      border-bottom: 1px solid $red;
      display: flex;
      justify-content: space-between;

      @include breakpoint-for(lg) {
        border: none;
      }

      h3 {
        font-size: 1.1rem;

        @include breakpoint-for(lg) {
          color: $red;
          font-family: Morganite-Bold, sans-serif;
          font-size: 7.5rem;
          letter-spacing: 0.97px;
          margin: 0;
        }
      }

      & > img {
        width: 12px;

        @include breakpoint-for(lg) {
          display: none;
        }
      }
    }
    .business {
      height: 0;
      overflow: hidden;
      position: static;
      width: 100%;

      span{
          text-decoration: line-through;
      }

      @include breakpoint-for(lg) {
        font-size: 1.1rem;
        font-weight: 100;
        height: 100%;
      }

      &.active {
        height: 100%;
      }

      & > ul {
        list-style-type: none;
        padding: 0 20px;

        @include breakpoint-for(lg) {
          padding: 0;

          & > li {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
