.teacher {
  color: #ffffff;

  @include breakpoint-for(lg) {
    margin-top: 100px;
  }

  h2 {
    font-family: Morganite-Bold, sans-serif;
    font-size: 4rem;
    letter-spacing: 5.57px;
    margin: 50px 16px;

    @include breakpoint-for(lg) {
      font-size: 7.5rem;
      margin: 50px;
      text-align: right;
      width: 85%;
    }
  }

  button{
    background-color: transparent;
    border: 1px solid $red;
    border-radius: 100px;
    color: $red;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    height: 30px;
    margin-top: 30px;
    margin-left: 30%;
    text-transform: uppercase;
    width: 140px;

    @include breakpoint-for(lg) {
      margin-left: 40%;
    }
  }

  .bio {
    display: flex;
    height: 0;
    overflow: hidden;
    position: static;
    flex-direction: column;
    font-size: 1rem;
    justify-content: center;
    letter-spacing: 0.19px;
    margin: 30px 16px;

    &.active{
      height: 100%;
    }

    p {
      line-height: 30px;
      margin: 5px 0;

      @include breakpoint-for(lg) {
        font-size: 1.2rem;
        margin-left: 40px;
        padding-bottom: 10px;
        text-align: justify;
        width: 70%;
      }
    }
  }

  .photo-teacher {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint-for(lg) {
      display: flex;
      flex-direction: row;
      justify-content: left;
      margin: 0 60px;
      min-height: 500px;
      width: 80%;
    }

    & > div {
      margin: 0 16px 40px;

      @include breakpoint-for(lg) {
        margin: 0;
      }

      img {
        width: 95%;

        @include breakpoint-for(lg) {
          width: 370px;
          margin: 0;
        }
      }

      p {
        font-size: 1.2rem;
        letter-spacing: 0.19px;
        opacity: 1;
        text-align: left;
      }
    }
  }
}
