footer {
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.534);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0px 20px;
  width: 100%;

  @include breakpoint-for(lg) {
    align-items: flex-start;
    padding-bottom: 40px;
    min-height: 400px;
  }

  & > div {
    width: 90%;

    .video-mpills {
      display: none;

      @include breakpoint-for(lg) {
        display: flex;
        flex-direction: column;
        margin-left: 60px;
        justify-content: space-around;
      }

      h3 {
        font-size: 1.31rem;

        @include breakpoint-for(lg) {
          font-size: 1.55rem;
        }
      }
    }

    @include breakpoint-for(lg) {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 60px;
      width: 80%;
    }

    .about-mt,
    .product-mt,
    .contact {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0;

      @include breakpoint-for(lg) {
        align-items: flex-start;
      }

      h3 {
        font-size: 1.3rem;

        @include breakpoint-for(lg) {
          font-size: 1.55rem;
        }
      }

      & > a {
        color: white;
        padding-top: 10px;
        text-decoration: none;

        @include breakpoint-for(lg) {
          font-size: 1.1rem;
        }
      }
    }
    .product-mt {
      .product-detail {
        @include breakpoint-for(lg) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        div {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          @include breakpoint-for(lg) {
            align-items: flex-start;
            padding-right: 40px;
          }

          h3 {
            font-size: 1.3rem;

            @include breakpoint-for(lg) {
              font-size: 1.5rem;
            }
          }

          a {
            color: white;
            padding-top: 10px;
            text-decoration: none;

            @include breakpoint-for(lg) {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }

  & > .social {
    align-items: center;
    border-top: 0.8px solid rgba(255, 255, 255, 0.534);
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @include breakpoint-for(lg) {
      align-items: flex-end;
      box-sizing: border-box;
      flex-direction: row;
      justify-content: space-between;
      margin:10px 60px;
      width: 92%;
    }

    .logo-footer {
      margin-top: 30px;
      width: 40px;
    }

    &:last-child {
      a {
        padding-top: 10px;
        text-decoration: none;

        img {
          padding: 3px;
          width: 30px;

          @include breakpoint-for(lg) {
            width: 40px;
          }
        }
      }
    }
  }
}
