.styled-checkbox{
  position: relative;

  input[type=checkbox]{
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: -1;
  }

  span {
    border: 1px solid $red;
    display: block;
    height: 15px;
    width: 15px;
    cursor: pointer;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input:checked + span{
    background: $red;

    &:before{
      content: "✓";
    }
  }
}
