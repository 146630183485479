.contact {
  color: white;
  box-sizing: border-box;
  margin: 0 40px;

  @include breakpoint-for(lg) {
    margin: 20px 60px;
  }

  h2 {
    font-family: Morganite-Bold, sans-serif;
    font-size: 4rem;
    letter-spacing: 5.5px;
    margin-bottom: 10px;

    @include breakpoint-for(lg) {
      color: $red;
      font-size: 7rem;
    }
  }

  p {
    font-size: 1.2rem;
    padding-bottom: 25px;
  }

  .price{
    & > :first-child{
      text-decoration: line-through;
    }

    p{
      font-size: 1rem;
      letter-spacing: 0.49px;
      margin: 0;
      padding-bottom: 5px;

      @include breakpoint-for(lg) {
        font-size: 1.16rem;
      }
    }

    & > :nth-child(3){
      padding-bottom: 20px;
    }

    & > :last-child{
      padding-bottom: 60px;

      @include breakpoint-for(lg) {
        margin-bottom: 40px;
      }
    }
  }

  .form-lead {
    min-height: 550px;

    @include breakpoint-for(lg) {
      flex-direction: row;
      justify-content: left;
      min-height: 180px;
    }

    .labels{
      display: none;

      @include breakpoint-for(lg) {
        display: block;
      }

      label {
        cursor: pointer;
        margin-right: 50px;
        opacity: 0.47;

        &.active{
          opacity: 1;
        }

        &.error{
          opacity: 1;
          color: $red;
        }
      }
    }

    .fields {
      @include breakpoint-for(lg) {
        display: flex;
        overflow: hidden;
        width: 700px;
      }

      button {
        background-color: transparent;
        border: none;
        color: white;
        cursor: pointer;
        display: none;
        font-size: 1.5rem;
        height: 100%;
        margin: 0;
        outline: none;
        position: absolute;
        right: 40px;

        &:hover{
          border: none;
        }

        @include breakpoint-for(lg) {
          display: inline;
        }
      }

      div{
        position: relative;
        padding-bottom: 12px;

        &.error input{
          border-bottom: 1px solid $red;

          @include breakpoint-for(lg) {
            border: 1px solid $red;
          }
        }
      }

      input {
        background-color: transparent;
        border-radius: 5px;
        border: none;
        border-bottom: 1px solid #555;
        box-sizing: border-box;
        color: white;
        font-size: 1.1rem;
        height: 40px;
        margin-bottom: 16px;
        margin-right: 30px;
        outline: none;
        padding-left: 10px;
        width: 100%;

        &:focus {
          border-bottom: 1px solid white;
        }

        @include breakpoint-for(lg) {
          background-color: #191919;
          border-bottom: none;
          height: 70px;
          margin-top: 23px;
          width:700px;

          &:focus {
            border-bottom: none;
          }
        }
      }
    }
  }

  button.mobile-submit-button {
    background-color: transparent;
    border: none;
    color: $red;
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    height: 60px;
    margin: 30px auto 0;
    outline: none;
    width: 220px;

    &:after{
      border-bottom: 1px solid $red;
      content: "";
      display: block;
      margin: auto;
      width: 120px;
    }

    @include breakpoint-for(lg) {
      margin: 20px;
      width: 180px;

      &[type=submit]{
        display: none;
      }
    }
  }

  .feedback{
    background-color: red;
    font-weight: bold;
    margin: 30px;
    padding: 3px;

    @include breakpoint-for(lg) {
      margin: 0;
    }
  }

  .text-checkbox {
    display: none;
    @include breakpoint-for(lg) {
      display: flex;
      font-size: 1.2rem;
      padding: 18px 0;
    }
  }

  .checkbox-desktop {
    display: none;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 70px;

    @include breakpoint-for(lg) {
      display: flex;
    }

    label{
      padding-left: 10px;
      box-sizing: border-box;
    }

    & > div {
      width: 16.6%;
      display: flex;
      margin-bottom: 10px;
    }
  }
}
