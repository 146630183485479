#about {
  color: white;
  font-size: 1rem;
  letter-spacing: 0.29px;
  line-height: 25px;
  padding-left: 20px;
  width: 88%;

  @include breakpoint-for(lg) {
    display: flex;
    font-size: 1.3rem;
  }

  & > div:nth-child(1) {
    h2 {
      font-size: 1.1rem;
      font-weight: 600;
      letter-spacing: 5.4px;
      padding: 30px 0;
      text-decoration: line-through;
    }

    @include breakpoint-for(lg) {
      margin-left: 60px;

      h2 {
        font-size: 1.8rem;
        letter-spacing: 8.32px;
        margin-bottom: 20px;
        margin-left: 12px;
      }

      p {
        letter-spacing: 0.17px;
        transform: skewY(-2deg);
        width: 90%;
      }
    }
  }

  span {
    color: $red;
    text-decoration: line-through;
  }

  & > div:nth-child(2) {
    div {
      height: 0;
      overflow: hidden;
      position: static;
      width: 100%;

      &.active {
        height: 100%;
      }

      h2 {
        text-align: right;
      }

      @include breakpoint-for(lg) {
        height: 100%;
        padding-top: 100px;

        p {
          letter-spacing: 0.17px;
          opacity: 1;
          transform: skewY(-1.5deg);
        }

        h2 {
          font-size: 1.8rem;
          letter-spacing: 8.32px;
          margin-left: 12px;
          margin-top: 50px;
        }
      }
    }

    button {
      background-color: transparent;
      border: none;
      color: $red;
      cursor: pointer;
      font-size: 1rem;
      font-style: italic;
      font-weight: bold;
      height: 60px;
      margin-top: 30px;
      outline: none;
      width: 100%;

      @include breakpoint-for(lg) {
        display: none;
      }
    }
  }
}

.video {
  display: none;

  @include breakpoint-for(lg) {
    display: block;
    margin: 150px 0;
    text-align: center;
  }
}
