.carousel {
  background-color: white;
  box-sizing: border-box;
  padding: 60px 0;
  width: 100%;

  @include breakpoint-for(lg) {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 800px;
  }

  .text {
    margin: 20px 45px;

    @include breakpoint-for(lg) {
      margin: 0px 60px;
      width: 50%;
    }

    h2 {
      color: black;
      font-family: Morganite-ExtraBold, sans-serif;
      font-size: 5rem;
      letter-spacing: 2.3px;
      padding-top: 40px;
      margin: 0;
      text-align: center;

      @include breakpoint-for(lg) {
        font-size: 7rem;
        letter-spacing: 6.05px;
        margin: 0;
        text-align: left;
      }
    }

    p {
      display: none;

      @include breakpoint-for(lg) {
        display: block;
        font-size: 1.1rem;
        letter-spacing: 0.14px;
        margin: 0;
        text-align: left;
        width: 70%;
      }
      
      &:nth-child(3){
        padding-bottom: 20px;
      }
    }

    a{
      text-decoration: none;
    }

    button {
      display: none;

      @include breakpoint-for(lg) {
        background-color: transparent;
        border: none;
        color: $red;
        cursor: pointer;
        display: block;
        font-size: 1rem;
        font-weight: bold;
        height: 60px;
        margin-top: 30px;
        outline: none;
        text-align: center;
      }
    }
  }

  .slider-container {
    overflow: hidden;
    
    @include breakpoint-for(lg) {
      padding: 10px;
      width: 50%;
    }
    .slides {
      display: flex;
      padding: 20px 0;
      position: relative;
      transition: transform 250ms ease-in;
      width: 100%;

      a {
        box-sizing: border-box;
        flex-shrink: 0;
        padding: 0 16px;
        width: 100%;

        img {
          width: 100%;
        }
        @include breakpoint-for(lg) {
          width: 328px;
        }
      }
    }
  }

  .dots-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 50px;

    span {
      background-color:#cdd1ce;
      border: 1px solid #c8ccc9;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 12px;
      margin: 0 5px;
      width: 12px;

      &.active {
        background-color: $red;
      }
    }
  }
}
