.modal {
  background-color: #fff;
  display: none;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  transition: 0.5s;
  top: 0;
  z-index: 3;

  &.right{
    right: 0;
    transform: translateX(100%);
  }

  &.left{
    left: 0;
    transform: translateX(-100%);
  }

  &.active{
    display: block;
  }

  &.visible{
    transform: translateX(0);
  }

  .container {
    flex-direction: row;
    margin-top: 70px;
    padding: 20px;

    @include breakpoint-for(lg) {
      padding: 20px 50px;
      overflow-y: auto;
      right: -50px;
    }

    button:first-child {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 1.3rem;
      font-weight: 700;
      margin: 20px 0;
      text-align: left;
      width: 100%;
    }

    h2 {
      font-size: 3rem;
      font-family: Morganite-Bold, sans-serif;
      letter-spacing: 5.57px;
      margin: 40px 0;
      text-transform: uppercase;

      @include breakpoint-for(lg) {
        font-size: 7.5rem;
      }
    }

    img {
      width: 95%;
      @include breakpoint-for(lg) {
        height: 315px;
        width: 560px;
      }
    }

    h3 {
      font-family: Barlow-Bold, sans-serif;
      font-size: 1.2rem;
      font-style: italic;

      @include breakpoint-for(lg) {
        width: 700px;
      }
    }

    p {
      font-size: 1rem;
      letter-spacing: 1.2px;
      padding-bottom: 5px;

      @include breakpoint-for(lg) {
        font-size: 1.1rem;
        letter-spacing: 1.3px;
        width: 723px;
      }
    }
  }
  .button-form {
    background-color: $red;
    border: none;
    border-radius: 4px;
    color: $black;
    font-weight: 600;
    font-size: 1rem;
    height: 32px;
    margin: 10px;
    padding: 2px 5px;
    text-align: center;
    text-transform: uppercase;
    width: 90%;

    @include breakpoint-for(lg) {
      margin: 20px 50px;
      width: 300px;
    }
  }
  .notice{
    font-style: italic;
    margin: 10px;
    padding: 2px 5px;

    @include breakpoint-for(lg) {
      margin: 5px 50px 20px;
    }
  }
}
