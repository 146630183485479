.title-course {
  & > h2 {
    color: #ffffff;
    font-family: Morganite-Bold, sans-serif;
    font-size: 3.5rem;
    letter-spacing: 5.57px;
    margin: 100px 16px 30px;;

    @include breakpoint-for(lg) {
      justify-content: center;
      font-size: 7.5rem;
      margin: 30px 60px;
    }
  }
}

.overlay{
  background-color: rgb(0,0,0); 
  background-color: rgba(0, 0, 0, 0.429); 
  left: 0;
  height: 100%; 
  position: fixed; 
  overflow: auto; 
  top: 0;
  width: 100%; 
  z-index: -2;
}

.course {
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  margin: 0 16px;

  @include breakpoint-for(lg) {
    margin: 30px 45px;
  }

  div {
    box-sizing: border-box;
    cursor: pointer;
    padding: 20px 0 30px;
    text-align: center;
    width: 100%;

    @include breakpoint-for(lg) {
      padding: 30px 20px;
      width: 48%;
    }
  }

  .image-frame {
    align-items: flex-end;
    background-position: center;
    background-size: cover;
    display: flex;
    height: 200px;
    margin: 0;
    width: 100%;

    @include breakpoint-for(lg) {
      align-items: center;
      justify-content: center;
      height: 400px;
    }
  }

  label {
    color: $red;
    border: 1px solid $red;
    bottom: 32px;
    border-radius: 10px;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 4px 17px;
    position: relative;

    @include breakpoint-for(lg) {
      bottom: 40px;
      font-size: 1.1rem;
    }
  }

  h3 {
    font-family: Morganite-Bold, sans-serif;
    font-size: 2rem;
    letter-spacing: 0.9px;
    margin: 3px;
    text-align: center;
    text-transform: uppercase;
    opacity: 1;

    @include breakpoint-for(lg) {
      margin-top: 15px;
      font-size: 3.2rem;
    }
  }

  span {
    font-size: 0.8rem;
    letter-spacing: 0.11px;
    margin-bottom: 20px;
    opacity: 1;
    text-align: center;

    @include breakpoint-for(lg) {
      font-size: 1.2rem;
      padding: 20px;
    }
  }
}
