header {
    background-image: url(" https://transformacao-digital-files.s3-sa-east-1.amazonaws.com/mobile/m-banner.png");
    background-position: center;
    background-size: cover;
    width: 100%;
  
    @include breakpoint-for(lg) {
      background-image: url(" https://transformacao-digital-files.s3-sa-east-1.amazonaws.com/desktop/d-banner.png");
    }
  
    & > p {
      color: white;
      font-family: Barlow-Medium, sans-serif;
      font-size: 1rem;
      padding: 2px 3px 5px 3px;
      position: absolute;
      text-align: center;
      text-decoration: line-through;
      z-index: 999;
  
      @include breakpoint-for(lg) {
        font-size: 1.6rem;
      }
  
      &:nth-child(1) {
        background-color: $black;
        left: 37px;
        top: 80px;
        width: 80px;
  
      @include breakpoint-for(lg) {
          left: 9%;
          top: 17%;
          width: 90px;
        }
      }

      &:nth-child(2) {
        background-color: $red;
        left: 95px;
        text-decoration: none;
        top: 120px;
        width: 70px;
  
        @include breakpoint-for(lg) {
          left: 12%;
          top: 25%;
          width: 80px;
        }
      }

      &:nth-child(3) {
        background-color: $black;
        left: 42px;
        top: 160px;
        width: 20px;
  
        @include breakpoint-for(lg) {
          left: 10%;
          top: 35%;
          width: 50px;
        }
      }

      &:nth-child(4) {
        background-color: $black;
        left: 80px;
        top: 190px;
        width: 50px;
  
        @include breakpoint-for(lg) {
          left: 10%;
          top: 45%;
          width: 80px;
        }
      }

      &:nth-child(5) {
        background-color: $black;
        left: 200px;
        top: 240px;
        width: 80px;
  
        @include breakpoint-for(lg) {
          left: 50%;
          top: 52%;
          width: 130px;
        }
      }

      &:nth-child(6) {
        background-color: $black;
        left: 70px;
        text-decoration: none;
        top: 420px;
        width: 100px;
  
        @include breakpoint-for(lg) {
          left: 51%;
          top: 60%;
          width: 170px;
        }
      }

      &:nth-child(7) {
        background-color: $black;
        left: 200px;
        top: 500px;
        width: 120px;
  
        @include breakpoint-for(lg) {
          left: 70%;
          top: 68%;
          width: 260px;
        }
      }
  
      span {
        color: $red;
        font-family: Barlow-Medium, sans-serif;
        font-size: 1rem;
  
        @include breakpoint-for(lg) {
          font-size: 1.6rem;
        }
      }
  
      &:nth-child(8) {
        background-color: $black;
        left: 230px;
        top: 540px;
        width: 80px;
  
        @include breakpoint-for(lg) {
          left: 76%;
          top: 75%;
          width: 130px;
        }
      }
      &:nth-child(9) {
        bottom: 80px;
        color: white;
        font-size: 0.8rem;
        left: 16px;
        text-decoration: none;

        @include breakpoint-for(lg) {
          bottom: 8%;
          font-size: 1rem;
          left: 60px;
          letter-spacing: 5px;
        }
      }

      &:nth-child(10) {
        bottom: 30px;
        color: white;
        font-size: 0.8rem;
        left: 16px;
        text-align: left;
        text-decoration: none;

        @include breakpoint-for(lg) {
          bottom: 5%;
          font-size: 1rem;
          left: 60px;
          letter-spacing: 5px;
        }
      } 
    }
  }
